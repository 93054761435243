export const refugeSurveyDefinition = {
    title: "ТИМЧАСОВЕ ЖИТЛО В ПОЛЬЩІ - ПОТРІБНА ДОПОМОГА - Ukraiński DOM",
    // checkErrorsMode: "onValueChanging",
    locale: 'ua',
    elements: [{
        "type": "panel",
        "elements": [
            {
                "type": "html",
                "html": "Якщо ви потребуєте житло, заповніть формуляр нижче і команда Українського дому у Варшаві постарається знайти місце для тимчасового перебування." +
                    "<br/><br/>" +
                    "Зверніть увагу!<br>" +
                    "<ol>" +
                    "<li>Допомога ВИКЛЮЧНО БЕЗКОШТОВНА, надана особами, які мешкають в Польщі.</li>" +
                    "<li>Український Дім в Варшаві намагається знайти житло, але не організовує транспорт.</li>" +
                    "<li>З інших питань звертайтеся на гарячу лінію Українського Дому в Варшаві +48 727 805 764.</li>" +
                    "<li>Кількість людей, які потребують житла, значно перевищує кількість людей, які його пропонують. Тому, на жаль, заповнення форми не гарантує того, що ми знайдемо вам помешкання. Ми рекомендуємо вам самостійно шукати житло також за допомогою інших ресурсів.</li>" +
                    "</ol>" +
                    "<br/>" +
                    "<b>СПИСОК ПОСИЛAНЬ, ДЕ ВИ МОЖЕТЕ ТАКОЖ ШУКАТИ ЖИТЛО</b>" +
                    "<ul>" +
                    "<li><b>Інформаційні пункти для громадян України</b> - https://warszawa19115.pl/-/informacijni-punkti-dla-gromadan-ukraini</li>" +
                    "<li><b>Інформаційна платформа безкоштовного житла для громадян України в Польщі</b> - https://uahelp.info/karta-rozmiscennja-misc-prozyvannja/</li>" +
                    "<li><b>Житло</b> - https://pomagamukrainie.gov.pl/ogloszenia/znajdz-pomoc/noclegi</li>" +
                    "<li><b>Помешкання, праця та інші типи допомоги</b> - https://pomocniludzie.pl/ua/</li>" +
                    "<li><b>Інформаційна платформа, де біженці з України можуть знайти житло на території Польщі та інших країн</b> - https://uasos.org/ua</li>" +
                    "</ul>" +
                    "" +
                    "GDPR/Згода на обробку персональних даних<br/>" +
                    "Заповнюючи анкету, я виражаю згоду на обробку моїх персональних даних фондом \"Наш вибір\", який знаходиться за адресою: Варшава, вул. Заменгофа, 1, з метою надання допомоги потерпілим внаслідок збройної агресії Російської Федерації проти України. Ваші персональні дані можуть бути передані іншим одержувачам та іншим організаціям що надають допомогу."
            }
        ],
        "name": "infoPanel"
    }, {
        name: "adultQuestion", //todo: use pages for it to not deal with conditional elements
        title: "Мені більше 18 років",
        type: "boolean",
        labelTrue: "Так",
        labelFalse: "Ні",
        isRequired: true,
        validators: [
            {
                type: "expression", //it doesn't work right with validation on value changed
                expression: "{adultQuestion} notempty && {adultQuestion} == true",
                text: "Вибачте, але вам має бути принаймні 18 років, щоб подати форму."
            }
        ],
    }, {
        "type": "panel",
        "elements": [
            {
                "type": "html",
                "html": "<b style='color: red'>На жаль, ми не можемо поселяти осіб віком до 18 років, які перебувають самостійно у Польщі, до приватних осіб, які пропонують безкоштовне тимчасове житло.<br/>" +
                    "Якщо ви перебуваєте в Польщі без опікуна та житла, найкраще звернутися до найближчого відділення поліції, звідки вас перенаправлять до центрів інституційної допомоги (pogotowie opiekuńcze).</b>"
            }
        ],
        "name": "notAdultInfoPanel",
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == false",
    }, {
        name: "currentPlace",
        title: "Де ви зараз знаходитеся?",
        type: "dropdown",
        choices: [
            {"text": 'В Польщі', "value": "inPoland"},
            {"text": 'В Україні', "value": "inUkraine"},
            {"text": 'На кордоні', "value": "onBorder"}
        ],
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true",
        isRequired: true,
        validators: [
            {
                type: "expression", //it doesn't work right with validation on value changed
                expression: "{currentPlace} notempty && {currentPlace} != 'inUkraine'",
                text: "Ви повинні бути в Польщі або на кордоні - деталі нижче"
            }
        ]
    },{
        "type": "panel",
        "elements": [
            {
                "type": "html",
                "html": "<b style='color: red'>На жаль, життєві обставини людей, які шукають житло перебуваючи в Україні, є нестабільними. " +
                    "Вони змінюють плани або не можуть дістатися до місця проживання в обумовлений термін. " +
                    "Тому, намагаючись ефективно допомогти, ми шукаємо житло для людей, які вже перебувають у Польщі або перетинають кордон.</b>"
            }
        ],
        "name": "notInPolandInfoPanel",
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} == 'inUkraine'",
    }, {
        name: "firstName",
        title: "Ім'я",
        description: "Якщо можете, напишіть своє ім’я латинськими літерами",
        type: "text",
        isRequired: true,
        "maxLength": 50,
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }, {
        name: "lastName",
        title: "Прізвище",
        description: "Якщо можете, напишіть своє прізвище латинськими літерами",
        type: "text",
        isRequired: true,
        "maxLength": 50,
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }, {
        name: "directionNumber",
        title: "Міжнародний телефонний код для вашого контактного телефону",
        type: "dropdown",
        isRequired: true,
        choices: [
            {text: '+48 (Польща)', value: '48'},
            {text: '+380 (Україна)', value: '380'},
            {text: '+49 (Німеччина)', value: '49'},
            {text: '+420 (Чехія)', value: '420'},
            {text: '+421 (Словаччина)', value: '421'},
            {text: '+43 (Австрія)', value: '43'},
            {text: '+36 (Угорщина)', value: '36'},
            {text: '+385 (Хорватія)', value: '385'},
            {text: '+386 (Словенія)', value: '386'},
            {text: '+40 (Румунія)', value: '40'},
            {text: '+359 (Болгарія)', value: '359'},
            {text: '+31 (Нідерланди)', value: '31'},
            {text: '+32 (Бельгія)', value: '32'},
            {text: '+45 (Данія)', value: '45'},
            {text: '+33 (Франція)', value: '33'},
            {text: '+34 (Іспанія)', value: '34'},
            {text: '+351 (Португалія)', value: '351'},
            {text: '+353 (Ірландія)', value: '353'},
            {text: '+44 (Великобританія)', value: '44'},
            {text: '+354 (Ісландія)', value: '354'},
            {text: '+47 (Норвегія)', value: '47'},
            {text: '+46 (Швеція)', value: '46'},
            {text: '+358 (Фінляндія)', value: '358'},
            {text: '+372 (Естонія)', value: '372'},
            {text: '+371 (Латвія)', value: '371'},
            {text: '+370 (Литва)', value: '370'},
            {text: '+30 (Греція)', value: '30'},
            {text: '+39 (Італія)', value: '39'},
            {text: '+423 (Ліхтенштейн)', value: '423'},
            {text: '+357 (Кіпр)', value: '357'},
            {text: '+356 (Мальта)', value: '356'},
            {text: 'інший', value: 'other'},
        ],
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }, {
        "name": "directionNumber-Comment",
        "title": "інший код міста",
        "type": "comment",
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine' && {directionNumber} contains 'other'",
        "maxLength": 15 //todo: czy tu nie powinno być isRequired
    }, {
        name: "phoneNumber",
        title: "Ваш контактний номер телефону",
        description: "Не вводьте код міста 0 або міжнародний код на початку, лише число у форматі XXX XXX XXX.",
        type: "text",
        isRequired: true,
        "maxLength": 15,
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
        validators: [
            {"type": "numeric"}
        ]
    }, {
        name: "email",
        title: " Емайл",
        description: "Введіть актуальну адресу електронної пошти.",
        type: "text",
        inputType: "email",
        "maxLength": 100,
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
        validators: [
            {"type": "email"}
        ]
    }, {
        name: "voivodeships",
        title: "Виберіть, які польські міста та воєводства ви берете до уваги?",
        description: "З нашого досвіду відомо, що багато людей шукають помешкання в столиці або інших великих містах Польщі. Проте розміщення в таких містах часто є неможливе, оскільки тут уже є велика кількість біженців. Тоді ми шукаємо житло в інших населених пунктах, які розміщені поблизу або по цілій території Польщі.<br/>" +
            "Можна вибрати декілька відповідей.",
        type: "checkbox",
        choices: [
            { "text": "Куди завгодно", "value": "all"},
            { "text": "Варшава та інші місця в Мазовецькому воєводстві", "value": "mazowieckie"},
            { "text": "Білосток та інші місця Підляського воєводства", "value": "podlaskie"},
            { "text": "Ольштин та інші місця Вармінсько-Мазурського воєводства", "value": "warminskoMazurskie"},
            { "text": "Тримісто, тобто Гданськ, Сопот, Гдиня та інші місця в Поморському воєводстві", "value": "pomorskie"},
            { "text": "Щецин та інші місця Західно-Поморського воєводства", "value": "zachodniopomorskie"},
            { "text": "Бидгощ і Торунь або інші місця в Куявсько-Поморському воєводстві", "value": "kujawskoPomorskie"},
            { "text": "Познань або інші місця Великопольського воєводства", "value": "wielkopolskie"},
            { "text": "Зелена Гура або інші місця в Любуському воєводстві", "value": "lubuskie"},
            { "text": "Вроцлав та інші місця Нижньошльонського воєводства", "value": "dolnoslaskie"},
            { "text": "Ополе та інших населених пунктах Опольського воєводства", "value": "opolskie"},
            { "text": "Катовіце та інші місця Сілезького воєводства", "value": "slaskie"},
            { "text": "Краків та інші місця Малопольського воєводства", "value": "malopolskie"},
            { "text": "Жешув та інші місця Підкарпатського воєводства", "value": "podkarpackie"},
            { "text": "Люблін та інші місця Люблінського воєводства", "value": "lubelskie"},
            { "text": "Кельце та інші місця Свентокшиського воєводства", "value": "swietokrzyskie"},
            { "text": "Лодзь та інші місця Лодзінського воєводства", "value": "lodzkie"},
        ],
        isRequired: true,
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }, {
        "type": "paneldynamic",
        "name": "adultMembers",
        "title": "Для скільки дорослих осіб (максимально) потрібне житло? Додайте кожну особу із зазначенням її віку і статі.",
        "description": "Якщо ваша група налічує більше 4-х дорослих осіб, рекомендуємо розділитись на менші групи. Це збільшить ймовірність того, що ми знайдемо вам помешкання.",
        "showQuestionNumbers": "off",
        "templateTitle": "Особа #{panelIndex}",
        "templateElements": [
            {
                "name": "sex",
                "type": "dropdown",
                "title": "Стать",
                "choices": [
                    {"text": "чоловіча", value: "male"},
                    {"text": "жіноча", value: "female"},
                    {"text": "інша", value: "other"},
                ],
                "isRequired": true
            }, {
                "name": "ageRange",
                "type": "dropdown",
                "title": "Віковий діапазон",
                choices: [
                    '18-24',
                    '25-34',
                    '35-49',
                    '50+',
                ],
                "isRequired": true
            }
        ],
        "minPanelCount": 1,
        "maxPanelCount": 4,
        "noEntriesText": "Люди ще не додані.\nЩоб додати, натисніть кнопку нижче.",
        "panelAddText": "Додайте наступну особу",
        "panelRemoveText": "видалити особу",
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }, {
        "type": "paneldynamic",
        "name": "childrenMembers",
        "title": "Для скількох дітей (до 18-літнього віку) окрім дорослих осіб? Додайте кожну особу із зазначенням її віку і статі.",
        "description": "Шукаємо житло для дітей тільки під наглядом дорослих.",
        "showQuestionNumbers": "off",
        "templateTitle": "Особа #{panelIndex}",
        "templateElements": [
            {
                "name": "sex",
                "type": "dropdown",
                "title": "Стать",
                "choices": [
                    {"text": "чоловіча", value: "male"},
                    {"text": "жіноча", value: "female"},
                    {"text": "інша", value: "other"},
                ],
                "isRequired": true
            }, {
                "name": "ageRange",
                "type": "dropdown",
                "title": "Віковий діапазон",
                choices: [
                    '0-5',
                    '6-9',
                    '10-14',
                    '15-17'
                ],
                "isRequired": true
            }
        ],
        "minPanelCount": 0,
        "maxPanelCount": 8,
        "noEntriesText": "Люди ще не додані.\nЩоб додати, натисніть кнопку нижче.",
        "panelAddText": "Додайте наступну особу",
        "panelRemoveText": "видалити особу",
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }, {
        name: "fromDate",
        title: "Від коли потребуєш житло?",
        description: "Склади заявку не раніше, аніж за 3 дні перед потрібною датою поселення (максимально - 5 днів).",
        type: "text",
        inputType: "date",
        isRequired: true,
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }, {
        name: "period",
        title: "На який період потрібне житло?",
        type: "dropdown",
        choices: [
            {
                "value": "upToWeek",
                "text": "На кілька днів - до тижня"
            }, {
                "value": "month",
                "text": "На місяць"
            }, {
                "value": "twoMonthsOrMore",
                "text": "На два місяці і більше"
            }
        ],
        isRequired: true,
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }, {
        name: "additionalNeeds",
        title: "Чи маєте ви особливі потреби щодо помешкання?",
        description: "Можна вибрати декілька відповідей.",
        type: "checkbox",
        choices: [
            {
                "text": "Перший поверх або будівля з ліфтом",
                "value": "firstFlorOrElevator",
            },
            {
                "text": "Доступне місце для людей в інвалідному візку",
                "value": "accessibleForWheelchairs",
            },
            {"text": "Ліжко для дитини", "value": "childBed"},
            {
                "text": "Можливість проживання з домашньою твариною. Якщо вибираєш цей пункт, необхідно в кінці анкети в питанні “Додаткова інформація” коротко описати вашого домашнього улюбленця.",
                "value": "petsAllowed",
            },
            {
                "text": "інший",
                "value": "other",
            },
        ],
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }, {
        "name": "additionalNeeds-Comment",
        "title": "Чи маєте ви особливі потреби щодо помешкання? (інший)",
        "type": "comment",
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine' && {additionalNeeds} contains 'other'",
        "maxLength": 1024
    }, {
        name: "allergies",
        title: "Чи є у вас алергія, яка не дозволяє жити разом з домашніми тваринами?",
        description: "Можна вибрати декілька відповідей.",
        type: "checkbox",
        isRequired: true,
        choices: [
            {"text": "Ні, у мене немає алергії, яка заважає мені жити в разом з тваринами", "value": null},
            {"text": "Так, у мене алергія на котів", "value": "cats"},
            {"text": "Так, у мене алергія на собак", "value": "dogs"},
            {"text": "Так, у мене алергія на гризунів (мишей, щурів, хом’яків)", "value": "rodents"},
            {"text": "інший", "value": "other"},
        ],
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }, {
        "name": "allergies-Comment",
        "title": "Чи є у вас алергія, яка не дозволяє жити разом з домашніми тваринами? (інший)",
        "type": "comment",
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine' && {allergies} contains 'other'",
        "maxLength": 512
    }, {
        name: "languages",
        title: "Якими мовами ви можете спілкуватися?",
        description: "Можна вибрати декілька відповідей.",
        type: "checkbox",
        choices: [
            {
                "value": "polish",
                "text": "Польська"
            }, {
                "value": "english",
                "text": "Англійська"
            }, {
                "value": "other",
                "text": "інший"
            }
        ],
        isRequired: true,
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }, {
        "name": "languages-Comment",
        "title": "Якими мовами ви можете спілкуватися? (інший)",
        "type": "comment",
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine' && {languages} contains 'other'",
        "maxLength": 1024
    }, {
        name: "groups",
        title: "Є різні варіанти розміщення, і ми хочемо забезпечити безпечне проживання для тих, хто до нас звертаються. Позначте, якщо представляєте яку-небудь із цих груп людей. Відповідь - добровільна і якщо це вас не стосується, можете пропустити це питання.",
        description: "Можна вибрати декілька відповідей.",
        type: "checkbox",
        choices: [
            {
                "text": "Особа похилого віку (самостійна)",
                "value": "elderlyPersonIndependent",
            },
            {
                "text": "Особа похилого віку (несамостійна - з опікуном)",
                "value": "elderlyPersonWithGuardian",
            },
            {
                "text": "Чоловік",
                "value": "man",
            },
            {
                "text": "Особа з неповносправністю (самостійна)",
                "value": "personWithDisabilityIndependent",
            },
            {
                "text": "Особа з неповносправністю (несамостійна - з опікуном)",
                "value": "personWithDisabilityWithGuardian",
            },
            {
                "text": "Біженець з України з громадянством, відмінним від українського",
                "value": "refugeeCitizenshipNotUkrainian",
            },
            {
                "text": "Біженець з України, з національністю відмінною від української",
                "value": "refugeeNationalityNotUkrainian",
            },
            {
                "text": "Особа, яка передставляє гендерну або сексуальну меншину (ЛГБТКA+)",
                "value": "LGBTKAPlus",
            },
            {
                "text": "інший",
                "value": "other"
            },
        ],
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }, {
        "name": "groups-Comment",
        "title": "Є різні варіанти розміщення, і ми хочемо забезпечити безпечне проживання для тих, хто до нас звертаються. Позначте, якщо представляєте яку-небудь із цих груп людей. Відповідь - добровільна і якщо це вас не стосується, можете пропустити це питання. (інший)",
        "type": "comment",
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine' && {group} contains 'other'",
        "maxLength": 255
    }, {
        name: "plans",
        title: "Які у вас плани на наступні шість місяців?",
        type: "checkbox",
        choices: [
            {"text": "переїхати за кордон", value: "moveAbroad"},
            {"text": "зняти квартиру/кімнату в Польщі", value: "rentApartmentOrRoomInPoland"},
            {"text": "знайти роботу та орендувати квартиру/кімнату в Польщі", value: "findJobAndRentApartmentOrRoomInPoland"},
            {"text": "ще не знаємо", value: "dontKnowYet"},
            {"text": "інший", "value": "other"},
        ],
        isRequired: true,
        visibleIf: "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }, {
        "name": "plans-Comment",
        "title": "Які у вас плани на наступні шість місяців? (інший)",
        "type": "comment",
        "visibleIf": "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine' && {group} contains 'other'",
        "maxLength": 255
    }, {
        name: "additionalInfo",
        title: "Додаткова інформація",
        type: "text",
        "maxLength": 2048,
        visibleIf: "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }, {
        name: "firstSubmission",
        title: "Чи вперше звертаєтесь до нас по допомогу в пошуку житла?",
        type: "boolean",
        labelTrue: "Так",
        labelFalse: "Ні",
        visibleIf: "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
        isRequired: true,
    }, {
        name: "gdprClause",
        type: "checkbox",
        choices: ["Rozumiem"],
        title: "1. Адміністратором ваших персональних даних є Фундація “Український Дім”.\n" +
            "2. Фундація “Український Дім” призначила Уповноважену особу із захисту персональних даних. Ви можете зв’язатися з даною особою, написавши електронного листа на адресу: iod@ukrainskidom.pl .\n" +
            "3. Ваші персональні дані обробляються на основі вашої добровільної згоди (art. 6 ust 1 pkt a RODO).\n" +
            "4. Персональні дані будуть оброблятися до моменту відкликання вашої добровільної згоди або до моменту досягнення цілі, для якої вони збиралися (залежно від того, яка обставина відбудеться першою).\n" +
            "5. Ви маєте право поскаржитися Голові управління захисту персональних даних, якщо ви вважаєте, що обробка ваших персональних даних порушує положення Загального регламенту захисту даних від 27 квітня 2016 року (RODO).\n" +
            "6. Ми не передаємо ваші персональні дані іншим особам. Ваші дані не будуть передані іншим країнам, а також міжнародним організаціям.\n" +
            "7. Ви маєте право на доступ до своїх персональних даних, а також право виправляти, видаляти і обмежувати їх обробку та передачу, право висувати заперечення та право відкликати згоду в будь-який час, не впливаючи на законність їхньої обробки до моменту відкликання згоди. Ви можете відкликати свою згоду на обробку персональних даних в будь-який час, надіславши електронний лист на адресу: iod@ukrainskidom.pl .\n" +
            "8. Ваші дані не будуть профілюватися або оброблятися автоматизованим способом.\n" +
            "9. Надання ваших персональних даних є добровільним, але ненадання перешкоджає обробці ваших персональних даних для цілей, зазначених вище.",
        isRequired: true,
        visibleIf: "{adultQuestion} != null && {adultQuestion} == true && {currentPlace} != null && {currentPlace} != 'inUkraine'",
    }]
};
