export const hostSurveyDefinition = {
    title: "OFERUJĘ KWATERUNEK - Ukraiński DOM",
    locale: "pl",
    elements: [
        {
            "type": "panel",
            "elements": [
                {
                    "type": "html",
                    "html": "<div>Jeśli chcesz udzielić tymczasowego schronienia/ udostępnić lokum dla osób uciekających z Ukrainy – wypełnij formularz. Zespół Ukraińskiego Domu w Warszawie będzie kontaktował się telefonicznie, proponując konkretne osoby do zakwaterowania.</div>" +
                        "<div>Żeby skuteczniej pomagać, możesz skontaktować się także z lokalnymi urzędem gminy lub organizacją, kwaterującymi uchodźców i uchodźczynie z Ukrainy.</div>" +
                        "</br>" +
                        "<div>Uwaga! Oferty pomocy przewidują udostępnienie mieszkania/ pokoju dla osób uchodźczych <b>wyłącznie nieodpłatnie</b> (co nie wyklucza korzystania z publicznych, np. rządowych dopłat 40+).</div>" +
                        "</br>" +
                        "<div>RODO: Wypełniając formularz zgadzam się na przetwarzanie moich danych osobowych przez Fundację Ukraiński Dom z siedzibą w Warszawie przy ul. Zamenhofa 1 w celu udzielenia pomocy/skorzystania z pomocy udzielanej potrzebującym poszkodowanym w zbrojnym ataku na Ukrainę przez Federację Rosyjską. Twoje dane osobowe mogą być przekazywane innym odbiorcom i organizacjom pomocowym. Jednocześnie oświadczam, że przekazuję moje dane osobowe całkowicie dobrowolnie. Oświadczam ponadto, że zostałam/em/ poinformowana/y/ o przysługującym mi prawie dostępu do treści tych danych, ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, a także o prawie wycofania zgody na przetwarzanie tych danych w każdym czasie.</div>"
                }
            ],
            "name": "infoPanel"
        },
        {
            name: "firstName",
            title: "Imię",
            type: "text",
            "maxLength": 50,
            isRequired: true,
        }, {
            name: "lastName",
            title: "Nazwisko",
            type: "text",
            "maxLength": 50,
            isRequired: true,
        }, {
            name: "directionNumber", //todo: not sure if it is ok that it's a list
            title: "Międzynarodowy numer kierunkowy Twojego telefonu kontaktowego",
            type: "dropdown",
            isRequired: true,
            choices: [
                {text: '+48 (Polska)', value: '48'},
                {text: '+380 (Україна)', value: '380'},
                {text: '+49 (Niemcy)', value: '49'},
                {text: '+420 (Czechy)', value: '420'},
                {text: '+421 (Słowacja)', value: '421'},
                {text: '+43 (Austria)', value: '43'},
                {text: '+36 (Węgry)', value: '36'},
                {text: '+385 (Chorwacja)', value: '385'},
                {text: '+386 (Słowenia)', value: '386'},
                {text: '+40 (Rumunia)', value: '40'},
                {text: '+359 (Bułgaria)', value: '359'},
                {text: '+31 (Holandia)', value: '31'},
                {text: '+32 (Belgia)', value: '32'},
                {text: '+45 (Dania)', value: '45'},
                {text: '+33 (Francja)', value: '33'},
                {text: '+34 (Hiszpania)', value: '34'},
                {text: '+351 (Portugalia)', value: '351'},
                {text: '+353 (Irlandia)', value: '353'},
                {text: '+44 (Wielka Brytania)', value: '44'},
                {text: '+354 (Islandia)', value: '354'},
                {text: '+47 (Norwegia)', value: '47'},
                {text: '+46 (Szwecja)', value: '46'},
                {text: '+358 (Finlandia)', value: '358'},
                {text: '+372 (Estonia)', value: '372'},
                {text: '+371 (Łotwa)', value: '371'},
                {text: '+370 (Litwa)', value: '370'},
                {text: '+30 (Grecja)', value: '30'},
                {text: '+39 (Włochy)', value: '39'},
                {text: '+423 (Liechtenstein)', value: '423'},
                {text: '+357 (Cypr)', value: '357'},
                {text: '+356 (Malta)', value: '356'},
                {text: 'Inny', value: 'other'},
            ],
        }, {
            "name": "directionNumber-Comment",
            "title": "Międzynarodowy numer kierunkowy Twojego telefonu kontaktowego (inny)",
            "type": "comment",
            "visibleIf": "{directionNumber} contains 'other'",
            "maxLength": 15
        }, {
            name: "phoneNumber",
            title: "Twój telefon kontaktowy",
            description: "Nie wpisuj kierunkowego 0 ani międzynarodowego kodu na początku, tylko numer w formacie XXX XXX XXX. Po wypełnieniu ankiety sprawdzaj proszę telefon, ponieważ często kontaktujemy się właśnie telefonicznie!",
            type: "text",
            isRequired: true,
            "maxLength": 15,
            validators: [
                {"type": "numeric", "text": "Wprowadzona wartość musi być numerem"}
            ]
        }, {
            name: "email",
            title: "E-mail",
            description: "Wprowadź aktualny adres mailowy.",
            type: "text",
            inputType: "email",
            "maxLength": 100,
            isRequired: true,
            validators: [
                {"type": "email", "text": "Wprowadzona wartość musi być prawidłowym adresem email"}
            ]
        }, {
            name: "voivodeship",
            title: "Szukamy kwaterunku dla osób uchodźczych z Ukrainy na terenie całej Polski. Wskaż gdzie oferujesz kwaterunek.",
            description: "Z naszego doświadczenia wynika, że większość osób poszukuje na początku możliwości zakwaterowania w Warszawie i innych dużych miastach, gdzie ofert jest najmniej ze względu na duży napływ mieszkańców. Wówczas szukamy kwaterunku w pobliskich miastach i miejscowościach w wybranym województwie. Wskaż jedną pozycję.",
            type: "radiogroup",
            choices: [
                { "text": "Warszawa i inne miejscowości w województwie mazowieckim", "value": "mazowieckie"},
                { "text": "Białystok i inne miejscowości w województwie podlaskim", "value": "podlaskie"},
                { "text": "Olsztyn i inne miejscowości w województwie warmińsko-mazurskim", "value": "warminskoMazurskie"},
                { "text": "Trójmiasto, czyli Gdańsk, Sopot, Gdynia i inne miejscowości w województwie pomorskim", "value": "pomorskie"},
                { "text": "Szczecin i inne miejscowości w województwie zachodniopomorskim", "value": "zachodniopomorskie"},
                { "text": "Bydgoszcz i Toruń lub inne miejscowości w województwie kujawsko-pomorskim", "value": "kujawskoPomorskie"},
                { "text": "Poznań lub inne miejscowości w województwie wielkopolskim", "value": "wielkopolskie"},
                { "text": "Zielona Góra lub inne miejscowości w województwie lubuskim", "value": "lubuskie"},
                { "text": "Wrocław i inne miejscowości w województwie dolnośląskim", "value": "dolnoslaskie"},
                { "text": "Opole i inne miejscowości w województwie opolskim", "value": "opolskie"},
                { "text": "Katowice i inne miejscowości w województwie śląskim", "value": "slaskie"},
                { "text": "Kraków i inne miejscowości w województwie małopolskim", "value": "malopolskie"},
                { "text": "Rzeszów i inne miejscowości w województwie podkarpackim", "value": "podkarpackie"},
                { "text": "Lublin i inne miejscowości w województwie lubelskim", "value": "lubelskie"},
                { "text": "Kielce i inne miejscowości w województwie świętokrzyskim", "value": "swietokrzyskie"},
                { "text": "Łódź i inne miejscowości w województwie łódzkim", "value": "lodzkie"},
            ],
            isRequired: true,
        }, {
            name: "zipCode",
            title: "Podaj kod pocztowy, gdzie oferujesz nocleg",
            type: "text",
            isRequired: true,
            inputFormat: "99-999"
        }, {
            name: "resourceType",
            title: "Jaką formę mieszkania możesz zaproponować?",
            type: "radiogroup",
            choices: [
                {"text": "Samodzielne mieszkanie lub dom", "value": "house"},
                {"text": "Wydzielona część domu czy lokalu, w którym przebywają inni ludzie", "value": "separate_part_of_apartment"},
                {"text": "Pokój w domu lub w mieszkaniu, gdzie mieszkasz", "value": "room_in_own_house"},
                {"text": "Dwa pokoje", "value": "two_rooms"},
                {"text": "Łóżko w pokoju współdzielonym", "value": "bed_in_shared_room"},
                {"text": "Miejsce w hotelu, hostelu, pensjonacie", "value": "place_in_hotel"},
                {"text": 'Inna', "value": 'other'},
            ],
            isRequired: true,
            otherText: "Inna (wpisz jaką)",
        }, {
            "name": "resourceType-Comment",
            "title": "Jaką formę mieszkania możesz zaproponować? (inna)",
            "type": "comment",
            "visibleIf": "{resourceType} contains 'other'",
            "maxLength": 1024
        }, {
            name: "facilities",
            title: "Czy Twoje mieszkanie posiada udogodnienia istotne z perspektywy gości?",
            description: "Można zaznaczyć kilka odpowiedzi.",
            type: "checkbox",
            "choices": [
                {
                    "value": "firstFlorOrElevator",
                    "text": "Parter lub budynek z windą"
                }, {
                    "value": "accessibleForWheelchairs",
                    "text": "Miejsce dostępne dla osób poruszających się na wózku"
                }, {
                    "value": "childBed",
                    "text": "Łóżeczko dla dziecka"
                }, {
                    "value": "petsAllowed",
                    "text": "Możliwość zakwaterowania osoby/osób ze zwierzęciem/tami"
                }, {
                    "value": 'other',
                    "text": 'Inne'
                },

            ],
            otherText: "Inne",
        }, {
            "name": "facilities-Comment",
            "title": "Czy Twoje mieszkanie posiada udogodnienia istotne z perspektywy gości? (inne)",
            "type": "comment",
            "visibleIf": "{facilities} contains 'other'",
            "maxLength": 1024
        }, {
            name: "adultsMaxCount",
            title: "Dla ilu dorosłych oferujesz kwaterunek (maksymalnie)?",
            type: "dropdown",
            isRequired: true,
            "choices": [
                "1", "2", "3", "4", "5", "6", "7", "8"
            ],
        }, {
            name: "childrenMaxCount",
            title: "Dla ilu dzieci (poniżej 18 r.ż.) maksymalnie możesz zaoferować nocleg?",
            description: "Kwaterujemy osoby nieletnie tylko pod opieką osób dorosłych.",
            type: "dropdown",
            isRequired: true,
            "choices": [
                "0", "1", "2", "3", "4", "5", "6", "7", "8"
            ],
        }, {
            name: "fromDate",
            title: "Od kiedy możesz przyjąć osoby potrzebujące kwaterunku?",
            type: "text",
            inputType: "date",
            isRequired: true,
        }, {
            name: "period",
            title: "Na jaki okres oferujesz mieszkanie?",
            description: "Można zaznaczyć kilka odpowiedzi.",
            type: "dropdown",
            "choices": [
                {
                    "value": "upToWeek",
                    "text": "Kilka dni - do tygodnia"
                }, {
                    "value": "month",
                    "text": "Na miesiąc"
                }, {
                    "value": "twoMonthsOrMore",
                    "text": "Na dwa miesiące i więcej"
                }, {
                    "value": "halfYear",
                    "text": "Pół roku"
                }, {
                    "value": "asLongAsNeeded",
                    "text": "Tak długo, jak potrzebne"
                }
            ],
            isRequired: true,
        }, {
            name: "canInvite",
            title: "Osoby, które mogę zakwaterować",
            description: "W ramach solidarnościowego kwaterunku najczęściej przyjmowane są matki z dziećmi (dziękujemy!), ale przed wojną uciekają także ojcowie z dziećmi, seniorzy, osoby z niepełnosprawnościami. Chcemy znaleźć bezpieczny nocleg dla każdej potrzebującej osoby. Dlatego prosimy o wskazanie dodatkowych profili osób, których wsparcie nie stanowi dla Ciebie problemu. Bardzo dziękujemy za Twoją otwartość." +
                "<br/>Można zaznaczyć kilka odpowiedzi.",
            type: "checkbox",
            "choices": [
                {
                    "value": "elderlyPersonIndependent",
                    "text": "Osoba starsza (samodzielna)"
                }, {
                    "value": "elderlyPersonWithGuardian",
                    "text": "Osoba starsza (niesamodzielna - z opiekunem/ką)"
                }, {
                    "value": "man",
                    "text": "Mężczyzna"
                }, {
                    "value": "personWithDisabilityIndependent",
                    "text": "Osoba z niepełnosprawnością (samodzielna)"
                }, {
                    "value": "personWithDisabilityWithGuardian",
                    "text": "Osoba z niepełnosprawnością (niesamodzielna - z opiekunem/ką)"
                }, {
                    "value": "refugeeCitizenshipNotUkrainian",
                    "text": "Uchodźca/czyni z Ukrainy o innym obywatelstwie niż ukraińskie"
                }, {
                    "value": "refugeeNationalityNotUkrainian",
                    "text": "Uchodźca/czyni z Ukrainy o innej narodowości niż ukraińska"
                }, {
                    "value": "LGBTKAPlus",
                    "text": "Osoba uchodźcza reprezentująca mniejszość płciową lub seksualną (LGBTQA+)"
                }, {
                    "value": "none",
                    "text": "Żaden/żadna z powyższych"//todo: I'm not sure of this question
                }
            ],
            isRequired: true,
        },
        {
            name: "anyAnimals",
            title: "Czy mieszkasz ze zwierzętami? Jest to istotne dla osób z alergiami.",
            description: "Możliwe zaznaczenie kilku odpowiedzi.",
            type: "checkbox",
            "choices": [
                {
                    "value": null,
                    "text": "Nie posiadam zwierząt",
                }, {
                    "value": "dogs",
                    "text": "Pies",
                }, {
                    "value": "cats",
                    "text": "Kot",
                }, {
                    "value": "rodents",
                    "text": "Gryzoń (chomik/ mysz/ szczur)",
                },
                {
                    "text": 'Inne',
                    "value": 'other'
                },
            ],
            isRequired: true,
        }, {
            "name": "anyAnimals-Comment",
            "title": "Czy mieszkasz ze zwierzętami? Jest to istotne dla osób z alergiami. (inne)",
            "type": "comment",
            "visibleIf": "{anyAnimals} contains 'other'",
            "maxLength": 512
        }, {
            name: "languages",
            title: "W jakich językach potrafisz się porozumieć?",
            description: "Można zaznaczyć kilka odpowiedzi.",
            type: "checkbox",
            "choices": [
                {
                    "value": "polish",
                    "text": "Polski"
                }, {
                    "value": "ukrainian",
                    "text": "Ukraiński"
                }, {
                    "value": "english",
                    "text": "Angielski"
                }, {
                    "value": "russian",
                    "text": "Rosyjski"
                }, {
                    "value": "belarusian",
                    "text": "Białoruski"
                }, {
                    "text": 'Inne',
                    "value": 'other'
                },
            ],
            isRequired: true,
        }, {
            "name": "languages-Comment",
            "title": "W jakich językach potrafisz się porozumieć? (inne)",
            "type": "comment",
            "visibleIf": "{languages} contains 'other'",
            "maxLength": 1024
        }, {
            name: "additionalInfo",
            title: "Dodatkowe informacje",
            description: "Wpisz tutaj dodatkowe informacje, które mogą być pomocne dla Twoich gości, np. jeśli chcesz zapewnić transportu, żeby odebrać kwaterowane osoby (nie jest to obowiązkowe), czy masz dzieci i w jakim wieku, a może chcesz zaoferować pracę lub inne dodatkowe wsparcie? Wpisz poniżej wszelkie informacje, które uznasz za ważne.",
            "maxLength": 2048,
            type: "text"
        }, {
            name: "endingInfo",
            type: "checkbox",
            choices: ["Rozumiem"],
            title: "Dziękujemy za czas poświęcony na wypełnienie formularza i chęć niesienia pomocy. Nie martw się, jeśli zanim zadzwonimy, zakwaterujesz inne osoby, potrzebujące pomocy - przed zakwaterowaniem zawsze weryfikujemy telefonicznie czy oferta jest jeszcze dostępna i pytamy, kiedy możemy do Ciebie wrócić. ",
            isRequired: true,
        }, {
            name: "gdprClause",
            type: "checkbox",
            choices: ["Rozumiem"],
            title: "1. Administratorem Pani/Pana danych osobowych jest Fundacja „Ukraiński Dom”.\n" +
                "2. Fundacja “Ukraiński Dom” powołała Inspektora Ochrony Danych. Można się z nim skontaktować pod adresem e-mail: iod@ukrainskidom.pl .\n" +
                "3. Pana/Pani dane osobowe przetwarzane są dobrowolnie na podstawie wyrażonej zgody (art. 6 ust 1 pkt a RODO).\n" +
                "4. Pani/Pana dane osobowe będą przetwarzane do czasu wycofania zgody lub do zakończenia realizacji celu w jakim są przetwarzane (w zależności od tego, które zdarzenie nastąpi jako pierwsze).\n" +
                "5. Ma Pana/Pani prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, gdy uzna Pana/Pani, iż przetwarzanie danych osobowych Pana/Pani dotyczących narusza przepisy ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 roku (RODO).\n" +
                "6. Nie udostępniamy Pani/Pana danych osobowych innym podmiotom. Pani/Pana dane nie będą przekazywane do krajów trzecich i organizacji międzynarodowych.\n" +
                "7. Posiada Pani/Pan prawo dostępu do treści swoich danych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo do wniesienia sprzeciwu oraz prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem. Zgoda może być przez Panią/Pana w dowolnym czasie wycofana poprzez przesłanie wiadomości e-mail na adres: iod@ukrainskidom.pl .\n" +
                "8. Pani/Pana dane nie będą profilowane ani przetwarzane w sposób zautomatyzowany.\n" +
                "9. Podanie przez Panią/Pana danych osobowych jest dobrowolne, ale ich niepodanie uniemożliwia przetwarzanie Pani/Pana danych osobowych w celu wskazanym powyżej.",
            isRequired: true,
        }]
}
