export const hostCompletingSurveyHtml = (referentialId) =>  "<table style='margin-left: auto; margin-right: auto'><tr>" +
  "<td style='vertical-align: top;'>FORMULARZ Z OFERTĄ ZAKWATEROWANIA<br/>" +
  "Twoje zgłoszenie zostało wysłane! <br/>" +
  "<b style='color: red'>Numer zgłoszenia: " + referentialId + "</b><br/>" +
  "<br/>" +
  "Zespół kwaterowania Ukraińskiego Domu skontaktuje się z Tobą, gdy znajdziemy osobę/rodzinę uchodźczą, której potrzeby związane z zakwaterowaniem będą zgodne z podanymi przez Ciebie kryteriami (np. lokalizacja, liczba osób, okres pobytu).<br/>" +
  "<br/>" +
  "<br/>" +
  "<br/>" +
  "WYNAJEM POKOJU/ MIESZKANIA:<br/>" +
  "W Centrum Wsparcia Ukraińskiego Domu przy ul. Andersa 29 <br/>" +
  "(pon-śr 9-19, czw-pt 10-19) <br/>" +
  "świadczymy konsultacje dotyczące wsparcia wynajmu mieszkań. <br/>" +
  "Jeśli chcesz wynająć mieszkanie/pokój osobie/rodzinie uchodźczej, zapraszamy! <br/>" +
  "<br/>" +
  "<br/>" +
  "<br/>" +
  "<br/>" +
  "MASZ DODATKOWE PYTANIA/ POTRZEBUJESZ INFORMACJI Z INNYCH OBSZARÓW DOTYCZĄCYCH WSPARCIA UCHODŹCÓW W POLSCE?<br/>" +
  "Infolinia Ukraińskiego Domu<br/>" +
  "+48 727 805 764" +
  "</td>" +
  "<td style='vertical-align: top;'>" +
  "АНКЕТА ДЛЯ ЗГОЛОШЕННЯ ПРОПОЗИЦІЇ ЖИТЛА<br/>" +
  "Твоя заявка надіслана!<br/>" +
  "<b style='color: red'>Номер заявки: " + referentialId + "</b><br/>" +
  "<br/>" +
  "Працівники відділу пошуку помешкання Українського дому зв’яжуться з Тобою, коли з’явиться особа/сім’я, потреба якої відповідатиме вказаним Тобою критеріям <br/>" +
  "(наприклад, місцезнаходження, кількість людей, термін перебування).<br/>" +
  "<br/>" +
  "<br/>" +
  "<br/>" +
  "ОРЕНДА КІМНАТИ/ПОМЕШКАННЯ<br/>" +
  "В центрі підтримки Українського дому на вулиці Андерса, 29<br/>" +
  "(пн-ср 9-19, чт-пт 10-19)<br/>" +
  "надаємо консультації щодо пошуку орендованого житла.<br/>" +
  "Якщо ви бажаєте здати квартиру/кімнату біженцю/біженці чи родині біженців, <br/>" +
  "запрошуємо!<br/>" +
  "<br/>" +
  "<br/>" +
  "МАЄШ ДОДАТКОВІ ЗАПИТАННЯ/ ПОТРІБНА ІНФОРМАЦІЯ З ІНШИХ ПИТАНЬ ЩОДО ПІДТРИМКИ БІЖЕНЦІВ У ПОЛЬЩІ?<br/>" +
  "Інфолінія Українського дому<br/>" +
  "+48 727 805 764" +
  "</td>" +
  "</tr></table>";


export const submissionCompletingHtml = (referentialId) => "" +
  "<table style='margin-left: auto; margin-right: auto'><tr>" +
  "<td style='vertical-align: top;'>АНКЕТА НА ПОШУК БЕЗКОШТОВНОГО ПОМЕШКАННЯ<br/>" +
  "Твоя заявка надіслана!<br/>" +
  "<b style='color: red'>Номер заявки: " + referentialId + "</b><br/>" +
  "<br/>" +
  "Протягом 2-3 робочих днів працівники відділу пошуку помешкання Українського дому зв'яжуться з Тобою по телефону для того, щоб почати пошук безкоштовного житла.<br/>" +
  "<br/>" +
  "ОРЕНДА КІМНАТИ/ПОМЕШКАННЯ<br/>" +
  "В центрі підтримки Українського дому на вулиці Андерса, 29<br/>" +
  "(пн-ср 9-19, чт-пт 10-19)<br/>" +
  "надаємо консультації щодо пошуку орендованого житла.<br/>" +
  "Запрошуємо!<br/>" +
  "<br/>" +
  "МАЄШ ДОДАТКОВІ ЗАПИТАННЯ / ПОТРІБНА ІНФОРМАЦІЯ З ІНШИХ ПИТАНЬ ЩОДО ПЕРЕБУВАННЯ В ПОЛЬЩІ?<br/>" +
  "Інфолінія Українського дому<br/>" +
  "+48 727 805 764<br/>" +
  "</td>" +
  "<td style='vertical-align: top;'>" +
  "FORMULARZ NA BEZPŁATNE ZAKWATEROWANIE<br/>" +
  "Twoje zgłoszenie zostało wysłane! <br/>" +
  "<b style='color: red'>Numer zgłoszenia: " + referentialId + "</b><br/>" +
  "<br/>" +
  "W ciągu 2-3 dni roboczych zespół kwaterowania Ukraińskiego Domu odezwie się do ciebie telefonicznie, żeby rozpocząć poszukiwanie darmowego zakwaterowania.<br/>" +
  "<br/>" +
  "<br/>" +
  "WYNAJEM POKOJU/ MIESZKANIA:<br/>" +
  "W Centrum Wsparcia Ukraińskiego Domu przy ul. Andersa 29 <br/>" +
  "(pon-śr 9-19, czw-pt 10-19) <br/>" +
  "świadczymy konsultacje dotyczące wsparcia wynajmu mieszkań. <br/>" +
  "Zapraszamy!<br/>" +
  "<br/>" +
  "<br/>" +
  "MASZ DODATKOWE PYTANIA/ POTRZEBUJESZ INFORMACJI Z INNYCH OBSZARÓW DOTYCZĄCYCH FUNKCJONOWANIA W POLSCE?<br/>" +
  "Infolinia Ukraińskiego Domu<br/>" +
  "+48 727 805 764" +
  "</td>" +
  "</tr></table>";
