import {hostSurveyDefinition} from "./housingResourceSurvey";
import {refugeSurveyDefinition} from "./submissionSurvey";

import React from "react";
import "survey-core/defaultV2.min.css";
import "./App.css";
import "survey-core/survey.i18n";
import {Converter} from "showdown";
import * as SurveyCore from "survey-core";
import {Model, StylesManager} from "survey-core";
import {Survey} from "survey-react-ui";
import * as widgets from "surveyjs-widgets";
import {
    hostCompletingSurveyHtml,
    submissionCompletingHtml,
} from "./completingTexts";

widgets.inputmask(SurveyCore);
StylesManager.applyTheme("defaultV2");

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {completionState: "init", referentialId: "foo"};
        const form = new URLSearchParams(window.location.search).get("form");
        if (form === "host") {
            this.surveyDefinition = hostSurveyDefinition;
            this.completionHTMLFactory = hostCompletingSurveyHtml;
            this.endpointSuffix = "/handleForm/createHousingResource";
        } else {
            this.surveyDefinition = refugeSurveyDefinition;
            this.completionHTMLFactory = submissionCompletingHtml;
            this.endpointSuffix = "/handleForm/createSubmission";
        }
        this.survey = new Model(this.surveyDefinition);
        const converter = new Converter();
        this.survey.onTextMarkdown.add(function(survey, options) { // Convert Markdown to HTML
            let str = converter.makeHtml(options.text);
            if (str.substring(0, 3) === "<p>") {
                str = str.substring(3);
                str = str.substring(0, str.length - 4);
            }
            options.html = str;
        });

        this.survey.onComplete.add(this.handleSubmit.bind(this));
    }

    handleSubmit(sender, options) {
        console.log("Handling survey submit", sender.data)
        this.setState((state) => {
            return {
                completionState: "inProgress",
                referentialId: state.referentialId,
            };
        });
        fetch("https://europe-central2-mieszkania-app-prod.cloudfunctions.net" + this.endpointSuffix, {
            method: "POST",
            body: JSON.stringify(sender.data),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        })
          .then((res) => {
              if (res.ok) {
                  return res.json();
              }
              throw new Error("Error during sending form to backend: " + res.status);
          })
          .then((responseJson) => {
              this.setState({
                  completionState: "completed",
                  referentialId: responseJson["referentialId"],
              });
          })
          .catch((err) => {
              console.log(err.message);
              this.setState((state) => {
                  return {
                      completionState: "error",
                      referentialId: state.referentialId,
                  };
              });
          });
    };

    render() {
        if (this.state.completionState === "inProgress") {
            return <h3 className={"sd-body sd-completedpage"}>відправлення /
                Trwa wysyłanie</h3>;
        } else if (this.state.completionState === "completed") {
            return <h3 className={"sd-body sd-completedpage"}
                       dangerouslySetInnerHTML={{__html: this.completionHTMLFactory(this.state.referentialId)}}></h3>;
        } else if (this.state.completionState === "error") {
            return <h3 className={"sd-body sd-completedpage"}>Виникла проблема -
                спробуйте ще раз / Napotkano problem - spróbuj ponownie</h3>;
        } else {
            return <Survey model={this.survey}/>;
        }
    }

}

export default App;
